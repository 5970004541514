
    import { Component, Watch, Vue } from 'vue-property-decorator';
    import CarGridItem from '@/components/selector/CarGridItem.vue';
    import {
        getBrandFilterIds,
        getCarFilterIds,
        getCO,
        getVehicleClass,
        getVehicleType,
        getRange,
        getSeats,
        getChargeTime,
        getChargeRate,
        getAcceleration,
        getDrive,
        getHitch,
        getAvailability,
        getNew,
        getUsed,
        getUsedYoung,
        getTrialSubscription,
        getBestDeal,
        removeQueryParam,
        getPrice,
    } from '@/util/queryfilter';
    import { OrderBy } from '@/enums/OrderBy';
    import { Country } from '@/enums/Country';
    import { getCarList } from '@/api/car';
    import { ICar } from '@/interfaces/car/ICar';
    import i18n from '@/i18n';
    import { Cooperation } from '@/enums/Cooperation';
    // @ts-ignore
    import { Affix } from 'vue-affix';
    import { SelectorQueryParam } from '@/enums/SelectorQueryParam';
    import CarLoader from '@/components/CarLoader.vue';
    import FilterTag from '@/components/tags/FilterTag.vue';
    import ScharingerHero from '@/components/selector/cooperations/ScharingerHero.vue';
    import PromoCard from '@/components/PromoCard.vue';
    import InstaHero from '@/components/selector/InstaHero.vue';
    import { AvailabilitySteps } from '@/enums/AvailabilitySteps';
    import { useGlobalStore } from '@/store/global';
    import { useSelectorStore } from '@/store/selector';
    import { useCustomerStore } from '@/store/customer';
    import { useConfiguratorStore } from '@/store/configurator';
    import B2BSwitch from '@/components/B2BSwitch.vue';
    import AllInclusiveCard from '@/components/AllInclusiveCard.vue';
    import QuizCard from '@/components/QuizCard.vue';
    @Component({
        components: {
            InstaHero,
            ScharingerHero,
            // has to be imported like that because of recursively importing GlobalStore Mixin
            FilterOffCanvas: () => import('@/components/selector/filter/FilterOffCanvas.vue'),
            CarGridItem,
            CarLoader,
            FilterTag,
            Affix,
            B2BSwitch,
            PromoCard,
            AllInclusiveCard,
            QuizCard,
        },
    })
    export default class SelectorView extends Vue {
        carsLoading = false; // used for loading animation
        sortings!: any;
        country!: any;
        cooperations!: any;

        get globalStore() {
            return useGlobalStore();
        }

        get selectorStore() {
            return useSelectorStore();
        }

        get customerStore() {
            return useCustomerStore();
        }

        get configuratorStore() {
            return useConfiguratorStore();
        }

        // return true if any of technical filters are active
        get hasFiltersTechnicalData(): boolean {
            return Boolean(
                this.selectorStore.filterParameter.rangeItem ||
                    this.selectorStore.filterParameter.chargeTimeDcItem ||
                    this.selectorStore.filterParameter.chargeRateDcMaxItem ||
                    this.selectorStore.filterParameter.accelerationItem ||
                    this.selectorStore.filterParameter.drives.length ||
                    this.selectorStore.filterParameter.hitch,
            );
        }

        get hasAnyFilter(): boolean {
            return (
                this.hasFilterAvailability ||
                this.hasFilterBrands ||
                this.hasFilterMiscBestDeal ||
                this.hasFilterVehicleCondition ||
                this.hasFilterMiscTrialSubscription ||
                this.hasFilterSeats ||
                this.hasFilterVehicleClass ||
                this.hasFilterVehicleType ||
                this.hasFilterPrice
            );
        }

        get hasFilterVehicleCondition(): boolean {
            return (
                this.selectorStore.filterParameter.misc.new ||
                this.selectorStore.filterParameter.misc.usedYoung ||
                this.selectorStore.filterParameter.misc.used
            );
        }

        get hasFilterMiscTrialSubscription(): boolean {
            return this.selectorStore.filterParameter.misc.trialSubscription;
        }

        get hasFilterMiscBestDeal(): boolean {
            return this.selectorStore.filterParameter.misc.bestDeal;
        }

        get hasFilterAvailability(): boolean {
            return this.selectorStore.filterParameter.availability !== AvailabilitySteps.ALL;
        }

        get hasFilterBrands(): boolean {
            return Boolean(this.selectorStore.filterParameter.brands.length);
        }

        get hasFilterPrice(): boolean {
            return Boolean(this.selectorStore.filterParameter.priceItem);
        }

        get hasFilterVehicleClass(): boolean {
            return Boolean(this.selectorStore.filterParameter.vehicleClasses.length);
        }

        get hasFilterVehicleType(): boolean {
            return Boolean(this.selectorStore.filterParameter.vehicleTypes.length);
        }

        get hasFilterSeats(): boolean {
            return Boolean(this.selectorStore.filterParameter.seats.length);
        }

        clearFiltersTechnicalData() {
            this.clearFilterRange();
            this.clearFilterChargeTime();
            this.clearFilterChargeRate();
            this.clearFilterAcceleration();
            this.clearFilterDrive();
            this.clearFilterHitch();
        }

        clearFilterAvailability() {
            this.selectorStore.clear.filterAvailability();
            this.selectorStore.applyFilter();
        }

        clearFilterBrands() {
            this.selectorStore.clear.filterBrands();
            this.selectorStore.applyFilter();
        }

        clearFilterPrice() {
            this.selectorStore.clear.filterPrice();
            this.selectorStore.applyFilter();
        }

        clearFilterVehicleClass() {
            this.selectorStore.clear.filterVehicleClass();
            this.selectorStore.applyFilter();
        }

        clearFilterVehicleType() {
            this.selectorStore.clear.filterVehicleType();
            this.selectorStore.applyFilter();
        }

        clearFilterSeats() {
            this.selectorStore.clear.filterSeats();
            this.selectorStore.applyFilter();
        }

        clearFilterRange() {
            this.selectorStore.clear.filterRange();
            this.selectorStore.applyFilter();
        }

        clearFilterChargeTime() {
            this.selectorStore.clear.filterChargeTime();
            this.selectorStore.applyFilter();
        }

        clearFilterChargeRate() {
            this.selectorStore.clear.filterChargeRate();
            this.selectorStore.applyFilter();
        }

        clearFilterAcceleration() {
            this.selectorStore.clear.filterAcceleration();
            this.selectorStore.applyFilter();
        }

        clearFilterHitch() {
            this.selectorStore.clear.filterHitch();
            this.selectorStore.applyFilter();
        }

        clearFilterDrive() {
            this.selectorStore.clear.filterDrive();
            this.selectorStore.applyFilter();
        }

        clearFilterMiscBestDeal() {
            this.selectorStore.filterParameter.misc.bestDeal = false;
            this.selectorStore.applyFilter();
        }

        clearFilterMiscVehicleCondition() {
            this.selectorStore.filterParameter.misc.new = false;
            this.selectorStore.filterParameter.misc.used = false;
            this.selectorStore.filterParameter.misc.usedYoung = false;
            this.selectorStore.applyFilter();
        }

        clearFilterMiscTrialSubscription() {
            this.selectorStore.filterParameter.misc.trialSubscription = false;
            this.selectorStore.applyFilter();
        }

        // Clears all filters and apply order
        clearFilterStore() {
            this.selectorStore.clearAllFilter();
            this.applyOrder();
        }

        async onFilterChanged() {
            // clear possible selector query params when filter changes
            removeQueryParam(SelectorQueryParam.CAR_FILTER, this.$route, this.$router);
            removeQueryParam(SelectorQueryParam.MAKE_FILTER, this.$route, this.$router);
            removeQueryParam(SelectorQueryParam.VEHICLE_CLASS_FILTER, this.$route, this.$router);
            removeQueryParam(SelectorQueryParam.VEHICLE_TYPE_FILTER, this.$route, this.$router);
            removeQueryParam(SelectorQueryParam.SEAT_FILTER, this.$route, this.$router);
            removeQueryParam(SelectorQueryParam.RANGE_FILTER, this.$route, this.$router);
            removeQueryParam(SelectorQueryParam.CHARGE_TIME_FILTER, this.$route, this.$router);
            removeQueryParam(SelectorQueryParam.CHARGE_RATE_FILTER, this.$route, this.$router);
            removeQueryParam(SelectorQueryParam.ACCELERATION_FILTER, this.$route, this.$router);
            removeQueryParam(SelectorQueryParam.DRIVE_FILTER, this.$route, this.$router);
            removeQueryParam(SelectorQueryParam.HITCH_FILTER, this.$route, this.$router);
            removeQueryParam(SelectorQueryParam.AVAILABILITY_FILTER, this.$route, this.$router);
            removeQueryParam(SelectorQueryParam.B2B_FILTER, this.$route, this.$router);
            removeQueryParam(SelectorQueryParam.BEST_DEAL, this.$route, this.$router);
            removeQueryParam(SelectorQueryParam.TRIAL_SUBSCRIPTION, this.$route, this.$router);
            removeQueryParam(SelectorQueryParam.NEW_FILTER, this.$route, this.$router);
            removeQueryParam(SelectorQueryParam.USED_FILTER, this.$route, this.$router);
            removeQueryParam(SelectorQueryParam.USED_YOUNG_FILTER, this.$route, this.$router);
            removeQueryParam(SelectorQueryParam.QUIZ_RESULT, this.$route, this.$router);
            removeQueryParam(SelectorQueryParam.PRICE, this.$route, this.$router);

            this.selectorStore.applyFilter();
        }

        @Watch('selectorStore.orderBy')
        applyOrder() {
            this.selectorStore.sortCars();
        }

        @Watch('customerStore.customer.is_company')
        async applyModeChange() {
            await this.retrieveCarList();
            this.selectorStore.applyFilter();
        }

        async retrieveCarList() {
            this.carsLoading = true;

            if (!this.globalStore.country) {
                throw new Error('country is undefined');
            }

            const resp = await getCarList(
                this.globalStore.country,
                this.customerStore.customer.is_company,
            );
            const retrievedCars = (await resp.parsedBody) as ICar[];

            this.selectorStore.allCars = retrievedCars;
            this.selectorStore.carsResult = retrievedCars;

            this.selectorStore.sortCars();

            this.carsLoading = false;
        }

        get filterButtonText(): string {
            if (this.carsLoading) {
                return i18n.tc('messages.cars_loading');
            }

            if (this.selectorStore.countCars() === 0 || this.selectorStore.countCars() === 1) {
                return i18n.tc('messages.filter_button', this.selectorStore.countCars());
            }

            return i18n.tc('messages.filter_button', this.selectorStore.countCars(), {
                count: this.selectorStore.countCars(),
                variations: this.selectorStore.countCars() * 12,
            });
        }

        get variationsText(): string {
            return i18n.tc('messages.variations', this.selectorStore.countCars(), {
                count: this.selectorStore.countCars() * 12,
            });
        }

        /**
         * Process the query filter arguments.
         */
        processQueryFilter() {
            // 1) check for possible cooperations
            if (getCO(this.$route)) {
                this.globalStore.cooperation = Cooperation.SCHARINGER;
            } else {
                this.globalStore.cooperation = undefined;
            }

            // 2) handle possible predefined filter via url query parameters
            const carFilterIds = getCarFilterIds(this.$route);

            // 2.1 Car Filter by IDs
            if (carFilterIds) {
                this.selectorStore.filterParameter.carIds = carFilterIds;
                this.selectorStore.applyFilter();
            }

            // 2.2 Brand Filter
            const brandFilterIds = getBrandFilterIds(this.$route);

            if (brandFilterIds) {
                brandFilterIds.forEach((brand) => {
                    if (this.globalStore.brands.find((b) => b.id === brand.valueOf())) {
                        this.selectorStore.appendFilterBrand(brand);
                    }
                });
                this.selectorStore.applyFilter();
            }

            // 2.3 Vehicle Class Filter
            const vehicleClass = getVehicleClass(this.$route);

            if (vehicleClass) {
                vehicleClass.forEach((vehicleClass) => {
                    if (
                        this.globalStore.vehicleClasses.find((c) => c.id === vehicleClass.valueOf())
                    ) {
                        this.selectorStore.appendFilterVehicleClass(vehicleClass);
                    }
                });
                this.selectorStore.applyFilter();
            }

            // 2.4 Vehicle Type Filter
            const vehicleType = getVehicleType(this.$route);

            if (vehicleType) {
                vehicleType.forEach((vehicleType) => {
                    if (this.globalStore.vehicleTypes.find((t) => t.id === vehicleType.valueOf())) {
                        this.selectorStore.appendFilterVehicleType(vehicleType);
                    }
                });
                this.selectorStore.applyFilter();
            }

            // 2.5 Seat Filter
            const seats = getSeats(this.$route);

            if (seats) {
                seats.forEach((seat) => {
                    if (this.selectorStore.allCars) {
                        if (
                            this.selectorStore.allCars?.find(
                                (car) =>
                                    car.technicaldata.seats_standard === seat ||
                                    car.technicaldata.seats_optional === seat,
                            )
                        ) {
                            this.selectorStore.appendFilterSeat(seat);
                        }
                    }
                });
                this.selectorStore.applyFilter();
            }

            // 2.6 Range Filter
            const range = getRange(this.$route);

            if (range) {
                this.selectorStore.filterParameter.rangeItem = {
                    from: range,
                    to: Number.MAX_SAFE_INTEGER,
                };
                this.selectorStore.applyFilter();
            }

            // 2.7 Charge Time Filter
            const chargeTime = getChargeTime(this.$route);

            if (chargeTime) {
                this.selectorStore.filterParameter.chargeTimeDcItem = { from: 0, to: chargeTime };
                this.selectorStore.applyFilter();
            }

            // 2.8 Charge Rate Filter
            const chargeRate = getChargeRate(this.$route);

            if (chargeRate) {
                this.selectorStore.filterParameter.chargeRateDcMaxItem = {
                    from: chargeRate,
                    to: Number.MAX_SAFE_INTEGER,
                };
                this.selectorStore.applyFilter();
            }

            // 2.9 Acceleration Filter
            const acceleration = getAcceleration(this.$route);

            if (acceleration) {
                this.selectorStore.filterParameter.accelerationItem = { from: 0, to: acceleration };
                this.selectorStore.applyFilter();
            }

            // 2.10 Drive Filter
            const drive = getDrive(this.$route);

            if (drive) {
                drive.forEach((d) => this.selectorStore.appendFilterDrive(d));
                this.selectorStore.applyFilter();
            }

            // 2.11 Hitch Filter
            const hitch = getHitch(this.$route);

            if (hitch) {
                this.selectorStore.filterParameter.hitch = hitch;
                this.selectorStore.applyFilter();
            }

            // 2.12 Acceleration Filter
            const availability = getAvailability(this.$route);

            if (availability) {
                this.selectorStore.filterParameter.availability = availability;
                this.selectorStore.applyFilter();
            }

            // 2.13 Used Filter
            const used = getUsed(this.$route);

            if (used) {
                this.selectorStore.filterParameter.misc.used = true;
                this.selectorStore.applyFilter();
            }

            // 2.14 Used Young Filter
            const usedYoung = getUsedYoung(this.$route);

            if (usedYoung) {
                this.selectorStore.filterParameter.misc.usedYoung = true;
                this.selectorStore.applyFilter();
            }

            // 2.15 Trial Subscription Filter
            const trialSubscription = getTrialSubscription(this.$route);

            if (trialSubscription) {
                this.selectorStore.filterParameter.misc.trialSubscription = trialSubscription === 1;
                this.selectorStore.applyFilter();
            }

            // 2.16 Best Deal Filter
            const bestDeal = getBestDeal(this.$route);
            if (bestDeal) {
                this.selectorStore.filterParameter.misc.bestDeal = true;
                this.selectorStore.applyFilter();
            }

            // 2.17 Range Filter
            const price = getPrice(this.$route);

            if (price) {
                this.selectorStore.filterParameter.priceItem = {
                    from: 0,
                    to: price,
                };
                this.selectorStore.applyFilter();
            }

            // 2.18 Used Filter
            const newCars = getNew(this.$route);

            if (newCars) {
                this.selectorStore.filterParameter.misc.new = true;
                this.selectorStore.applyFilter();
            }
        }

        async mounted() {
            this.configuratorStore.priceRequest = undefined;
            this.configuratorStore.priceResult = undefined;

            if (!this.selectorStore.carsResult) {
                // retrieve cars if carsResult is undefined (not stored)
                await this.retrieveCarList();
            }

            this.processQueryFilter();
        }

        onDeleteFilter() {
            this.clearFilter();
        }

        /**
         * Clear the filter and show all cars.
         */
        clearFilter() {
            this.clearFilterStore();
            this.selectorStore.applyFilter();
        }

        beforeCreate() {
            this.sortings = OrderBy;
            this.country = Country;
            this.cooperations = Cooperation;
        }
    }
